var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Frame 427318638" } }, [
        _c("g", { attrs: { id: "Group" } }, [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M20.6339 18.866L18.891 17.1231C20.4498 15.1146 21.1846 12.5876 20.9458 10.0564C20.7071 7.52519 19.5127 5.18012 17.6058 3.49852C15.6989 1.81693 13.2229 0.925225 10.6817 1.00492C8.14054 1.08461 5.72524 2.12971 3.92747 3.92747C2.12971 5.72524 1.08461 8.14054 1.00492 10.6817C0.925225 13.2229 1.81693 15.6989 3.49852 17.6058C5.18012 19.5127 7.52519 20.7071 10.0564 20.9458C12.5876 21.1846 15.1146 20.4498 17.1231 18.891L18.866 20.6339C19.1004 20.8683 19.4184 21 19.7499 21C20.0815 21 20.3994 20.8683 20.6339 20.6339C20.8683 20.3994 21 20.0815 21 19.7499C21 19.4184 20.8683 19.1004 20.6339 18.866ZM16.3048 16.3048C15.2554 17.3505 13.9196 18.0619 12.4662 18.349C11.0128 18.6362 9.50688 18.4862 8.13863 17.9181C6.77039 17.3499 5.60117 16.3891 4.77862 15.1569C3.95607 13.9247 3.51708 12.4764 3.51708 10.9949C3.51708 9.51342 3.95607 8.06513 4.77862 6.83294C5.60117 5.60075 6.77039 4.63993 8.13863 4.07179C9.50688 3.50365 11.0128 3.35368 12.4662 3.64082C13.9196 3.92795 15.2554 4.63932 16.3048 5.68508C17.0036 6.38151 17.5581 7.20904 17.9365 8.12023C18.3148 9.03141 18.5096 10.0083 18.5096 10.9949C18.5096 11.9815 18.3148 12.9585 17.9365 13.8696C17.5581 14.7808 17.0036 15.6084 16.3048 16.3048Z",
              fill: "#1FA4F4",
            },
          }),
        ]),
        _c("rect", {
          attrs: {
            id: "Rectangle 418",
            x: "7",
            y: "10",
            width: "9",
            height: "2.6",
            rx: "1.3",
            fill: "#1FA4F4",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }