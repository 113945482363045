var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        viewBox: "259.579 230.581 11 14",
        width: "11",
        height: "14",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "btn/text",
            transform: "matrix(1, 0, 0, 1, 247.578827, 220.580994)",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M 19.949 10 L 21.925 10 L 21.925 16.69 C 21.925 17.423 21.74 18.068 21.371 18.625 C 21.005 19.182 20.491 19.617 19.827 19.93 C 19.163 20.24 18.387 20.395 17.5 20.395 C 16.609 20.395 15.832 20.24 15.168 19.93 C 14.504 19.617 13.99 19.182 13.624 18.625 C 13.258 18.068 13.076 17.423 13.076 16.69 L 13.076 10 L 15.051 10 L 15.051 16.535 C 15.051 16.962 15.15 17.342 15.349 17.675 C 15.551 18.008 15.835 18.27 16.201 18.46 C 16.567 18.647 17 18.74 17.5 18.74 C 18.001 18.74 18.434 18.647 18.799 18.46 C 19.168 18.27 19.452 18.008 19.651 17.675 C 19.85 17.342 19.949 16.962 19.949 16.535 L 19.949 10 Z",
              fill: "#808080",
            },
          }),
          _c("path", {
            attrs: {
              d: "M 12 23.04 L 23 23.04 L 23 24 L 12 24 L 12 23.04 Z",
              fill: "#808080",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }