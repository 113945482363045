var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "arrow (1) 1" } }, [
        _c("g", { attrs: { id: "Layer 34" } }, [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M7.03146 4.40979H12.7971L11.8408 3.45354C11.6377 3.21218 11.5327 2.90325 11.5467 2.58807C11.5607 2.27289 11.6927 1.9745 11.9165 1.75212C12.1403 1.52975 12.4395 1.39964 12.7548 1.38763C13.07 1.37562 13.3783 1.48258 13.6183 1.68729L16.7121 4.78104C16.831 4.90026 16.9248 5.04204 16.9881 5.19805C17.0513 5.35406 17.0828 5.52114 17.0805 5.68948C17.0776 6.01817 16.9452 6.33246 16.7121 6.56416L13.6183 9.66916C13.3823 9.90488 13.0622 10.0371 12.7286 10.0369C12.395 10.0366 12.0751 9.90384 11.8394 9.66776C11.6037 9.43167 11.4714 9.11162 11.4717 8.77801C11.472 8.4444 11.6048 8.12456 11.8408 7.88885L12.8027 6.92698H7.03146C6.06997 6.92698 5.14786 7.30893 4.46798 7.98881C3.7881 8.66868 3.40615 9.5908 3.40615 10.5523C3.40615 11.5138 3.7881 12.4359 4.46798 13.1158C5.14786 13.7956 6.06997 14.1776 7.03146 14.1776H15.8374C16.1731 14.1776 16.495 14.3109 16.7323 14.5483C16.9697 14.7856 17.103 15.1076 17.103 15.4432C17.103 15.7789 16.9697 16.1008 16.7323 16.3382C16.495 16.5755 16.1731 16.7088 15.8374 16.7088H7.03146C5.40237 16.7088 3.84 16.0617 2.68806 14.9098C1.53612 13.7578 0.888964 12.1954 0.888964 10.5664C0.888964 8.93726 1.53612 7.37489 2.68806 6.22295C3.84 5.07101 5.40237 4.42385 7.03146 4.42385V4.40979Z",
              fill: "#A7A7A7",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }