import { render, staticRenderFns } from "./StoryCityGradientButton.vue?vue&type=template&id=b8f39418&scoped=true"
import script from "./StoryCityGradientButton.vue?vue&type=script&lang=js"
export * from "./StoryCityGradientButton.vue?vue&type=script&lang=js"
import style0 from "./StoryCityGradientButton.vue?vue&type=style&index=0&id=b8f39418&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f39418",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/web-client/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8f39418')) {
      api.createRecord('b8f39418', component.options)
    } else {
      api.reload('b8f39418', component.options)
    }
    module.hot.accept("./StoryCityGradientButton.vue?vue&type=template&id=b8f39418&scoped=true", function () {
      api.rerender('b8f39418', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Buttons/StoryCityGradientButton.vue"
export default component.exports