var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "22",
        height: "22",
        viewBox: "0 0 22 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Frame 427318638" } }, [
        _c("g", { attrs: { id: "Group" } }, [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M14.1247 9.75235H12.2506V7.87828C12.2506 7.54693 12.119 7.22914 11.8847 6.99484C11.6504 6.76054 11.3326 6.62891 11.0012 6.62891C10.6699 6.62891 10.3521 6.76054 10.1178 6.99484C9.88349 7.22914 9.75186 7.54693 9.75186 7.87828V9.75235H7.87779C7.54644 9.75235 7.22866 9.88398 6.99435 10.1183C6.76005 10.3526 6.62842 10.6704 6.62842 11.0017C6.62842 11.3331 6.76005 11.6509 6.99435 11.8852C7.22866 12.1195 7.54644 12.2511 7.87779 12.2511H9.75186V14.1252C9.75186 14.4565 9.88349 14.7743 10.1178 15.0086C10.3521 15.2429 10.6699 15.3745 11.0012 15.3745C11.3326 15.3745 11.6504 15.2429 11.8847 15.0086C12.119 14.7743 12.2506 14.4565 12.2506 14.1252V12.2511H14.1247C14.456 12.2511 14.7738 12.1195 15.0081 11.8852C15.2424 11.6509 15.374 11.3331 15.374 11.0017C15.374 10.6704 15.2424 10.3526 15.0081 10.1183C14.7738 9.88398 14.456 9.75235 14.1247 9.75235Z",
              fill: "#1FA4F4",
            },
          }),
          _c("path", {
            attrs: {
              id: "Vector_2",
              d: "M20.6339 18.866L18.891 17.1231C20.4498 15.1146 21.1846 12.5876 20.9458 10.0564C20.7071 7.52519 19.5127 5.18012 17.6058 3.49852C15.6989 1.81693 13.2229 0.925225 10.6817 1.00492C8.14054 1.08461 5.72524 2.12971 3.92747 3.92747C2.12971 5.72524 1.08461 8.14054 1.00492 10.6817C0.925225 13.2229 1.81693 15.6989 3.49852 17.6058C5.18012 19.5127 7.52519 20.7071 10.0564 20.9458C12.5876 21.1846 15.1146 20.4498 17.1231 18.891L18.866 20.6339C19.1004 20.8683 19.4184 21 19.7499 21C20.0815 21 20.3994 20.8683 20.6339 20.6339C20.8683 20.3994 21 20.0815 21 19.7499C21 19.4184 20.8683 19.1004 20.6339 18.866ZM16.3048 16.3048C15.2554 17.3505 13.9196 18.0619 12.4662 18.349C11.0128 18.6362 9.50688 18.4862 8.13863 17.9181C6.77039 17.3499 5.60117 16.3891 4.77862 15.1569C3.95607 13.9247 3.51708 12.4764 3.51708 10.9949C3.51708 9.51342 3.95607 8.06513 4.77862 6.83294C5.60117 5.60075 6.77039 4.63993 8.13863 4.07179C9.50688 3.50365 11.0128 3.35368 12.4662 3.64082C13.9196 3.92795 15.2554 4.63932 16.3048 5.68508C17.0036 6.38151 17.5581 7.20904 17.9365 8.12023C18.3148 9.03141 18.5096 10.0083 18.5096 10.9949C18.5096 11.9815 18.3148 12.9585 17.9365 13.8696C17.5581 14.7808 17.0036 15.6084 16.3048 16.3048Z",
              fill: "#1FA4F4",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }