var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "20",
        height: "12",
        viewBox: "0 0 20 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.335852 12V0.363636H2.44381V5.28977H7.83585V0.363636H9.94949V12H7.83585V7.05682H2.44381V12H0.335852ZM11.9085 12V10.4773L15.9482 6.51705C16.3346 6.12689 16.6566 5.7803 16.9141 5.47727C17.1717 5.17424 17.3649 4.88068 17.4937 4.59659C17.6225 4.3125 17.6869 4.00947 17.6869 3.6875C17.6869 3.32008 17.6035 3.00568 17.4369 2.74432C17.2702 2.47917 17.041 2.27462 16.7494 2.13068C16.4577 1.98674 16.1263 1.91477 15.7551 1.91477C15.3725 1.91477 15.0373 1.99432 14.7494 2.15341C14.4615 2.30871 14.238 2.5303 14.0789 2.81818C13.9236 3.10606 13.846 3.44886 13.846 3.84659H11.8403C11.8403 3.10795 12.0088 2.46591 12.346 1.92045C12.6831 1.375 13.1471 0.952651 13.738 0.653409C14.3327 0.354166 15.0145 0.204545 15.7835 0.204545C16.5638 0.204545 17.2494 0.350378 17.8403 0.642045C18.4312 0.933712 18.8895 1.33333 19.2153 1.84091C19.5448 2.34848 19.7096 2.92803 19.7096 3.57955C19.7096 4.01515 19.6263 4.44318 19.4596 4.86364C19.2929 5.28409 18.9994 5.75 18.5789 6.26136C18.1623 6.77273 17.577 7.39205 16.8232 8.11932L14.8176 10.1591V10.2386H19.8857V12H11.9085Z",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }