var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "31",
          height: "31",
          rx: "7.5",
          fill: "#1FA4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.293 11.2924L14 17.5854L11.707 15.2924C11.5184 15.1103 11.2658 15.0095 11.0036 15.0118C10.7414 15.014 10.4906 15.1192 10.3052 15.3046C10.1198 15.49 10.0146 15.7408 10.0124 16.003C10.0101 16.2652 10.1109 16.5178 10.293 16.7064L13.293 19.7064C13.4806 19.8939 13.7349 19.9992 14 19.9992C14.2652 19.9992 14.5195 19.8939 14.707 19.7064L21.707 12.7064C21.8892 12.5178 21.99 12.2652 21.9877 12.003C21.9854 11.7408 21.8803 11.49 21.6949 11.3046C21.5095 11.1192 21.2586 11.014 20.9964 11.0118C20.7342 11.0095 20.4816 11.1103 20.293 11.2924Z",
          fill: "white",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "31",
          height: "31",
          rx: "7.5",
          stroke: "#1FA4F4",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }