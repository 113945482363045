var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home text-center" },
    [
      _c("v-img", {
        staticClass: "my-3",
        attrs: {
          src: require("@/assets/logo.svg"),
          contain: "",
          height: "120",
        },
      }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", [_vm._v(_vm._s(_vm.$t("becomeACreator.welcome")))]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("becomeACreator.startWalkthrough"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("iframe", {
                  attrs: {
                    width: "860",
                    height: "485",
                    src: "https://www.youtube.com/embed/dN94GZpoIic",
                    title: "YouTube video player",
                    frameborder: "0",
                    allow:
                      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                    allowfullscreen: "",
                  },
                }),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _vm.isCreator
                  ? _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(_vm.$t("becomeACreator.startCreating"))
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [_c("v-col", [_c("CreateStoryButton")], 1)],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm.user
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "becomeACreator.startMakingAdventure"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-5",
                                    attrs: {
                                      to: { name: "become-creator" },
                                      color: "primary",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("becomeACreator.becomeCreator")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "secondaryBackground" } },
            [
              _c("v-card-title", { staticClass: "text-center" }, [
                _c("h3", { staticStyle: { width: "100%" } }, [
                  _vm._v(_vm._s(_vm.$t("register.accountVerification"))),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-15", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: { size: "65", color: "green" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "white",
                                                size: "80",
                                              },
                                            },
                                            [_vm._v("mdi-check-circle")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h2", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "register.verification.accountIsVerified"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-15",
                                  attrs: { color: "primary", elevation: "0" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog = false
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("forms.ok")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }