var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    {
      class: "avatar ma-0" + (!_vm.hasBorder ? " no-border" : ""),
      attrs: { size: _vm.size },
    },
    [
      _vm.userProfile && _vm.userProfile.image
        ? _c("img", {
            key: _vm.userProfile.id,
            staticStyle: { "object-fit": "cover" },
            attrs: { src: _vm.userProfile.image.contentUrl },
          })
        : _c(
            "v-icon",
            { attrs: { size: _vm.size, color: "primary", dark: "" } },
            [_vm._v(" mdi-account-circle ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }