var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "RoleBadge",
    { attrs: { user: _vm.user, size: _vm.size } },
    [
      _c("Avatar", {
        attrs: { userProfile: _vm.user.userProfile, size: _vm.size },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }