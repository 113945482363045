var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "ic/genre", "clip-path": "url(#clip0_121_3108)" } },
        [
          _c("path", {
            attrs: {
              id: "XMLID_2320_",
              d: "M3.9703 14.7587H15.8821C16.3215 14.7587 16.6762 14.404 16.6762 13.9646V2.31756C16.6762 1.87814 16.3215 1.52344 15.8821 1.52344H3.9703C2.50912 1.52344 1.32324 2.70932 1.32324 4.1705V15.8176C1.32324 17.2787 2.50912 18.4646 3.9703 18.4646H15.8821C16.3215 18.4646 16.6762 18.1099 16.6762 17.6705C16.6762 17.2311 16.3215 16.8764 15.8821 16.8764H3.9703C3.38795 16.8764 2.91148 16.3999 2.91148 15.8176C2.91148 15.2352 3.38795 14.7587 3.9703 14.7587ZM9.56089 12.1434C9.41265 12.2917 9.20618 12.3764 8.99971 12.3764C8.79324 12.3764 8.58677 12.2917 8.43854 12.1434C8.2903 11.9952 8.20559 11.794 8.20559 11.5823C8.20559 11.3758 8.2903 11.1693 8.43854 11.0211C8.73501 10.7246 9.26442 10.7246 9.56089 11.0211C9.70912 11.1693 9.79383 11.3758 9.79383 11.5823C9.79383 11.794 9.70912 11.9952 9.56089 12.1434ZM7.46971 4.46167C8.00971 4.01167 8.72442 3.82108 9.42324 3.94285C10.3868 4.11226 11.1756 4.90108 11.345 5.86461C11.5197 6.87579 11.0432 7.88167 10.1485 8.37402C9.93148 8.49579 9.79383 8.76579 9.79383 9.07285V9.19991C9.79383 9.63932 9.43912 9.99403 8.99971 9.99403C8.5603 9.99403 8.20559 9.63932 8.20559 9.19991V9.07285C8.20559 8.18344 8.65559 7.38403 9.38089 6.98697C9.61383 6.85991 9.85207 6.55814 9.77795 6.13991C9.72501 5.82756 9.4603 5.56285 9.15324 5.50991C8.90442 5.46226 8.67148 5.52579 8.49148 5.67932C8.31148 5.83285 8.20559 6.0552 8.20559 6.28814C8.20559 6.72755 7.85089 7.08226 7.41148 7.08226C6.97207 7.08226 6.61736 6.72755 6.61736 6.28814C6.61736 5.58403 6.92971 4.91697 7.46971 4.46167Z",
              fill: "#20A5F5",
            },
          }),
        ]
      ),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_121_3108" } }, [
          _c("rect", {
            attrs: {
              width: "18",
              height: "18",
              fill: "white",
              transform: "translate(0 0.994141)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }