var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "17",
        height: "12",
        viewBox: "0 0 17 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.460852 12V0.363636H2.56881V5.28977H7.96085V0.363636H10.0745V12H7.96085V7.05682H2.56881V12H0.460852ZM16.7664 0.363636V12H14.6585V2.41477H14.5903L11.8687 4.15341V2.22159L14.7607 0.363636H16.7664Z",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }