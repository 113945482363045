var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "20",
        height: "14",
        viewBox: "0 0 20 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", { attrs: { width: "2", height: "2", fill: "#808080" } }),
      _c("rect", {
        attrs: { x: "4.27979", width: "15", height: "2", fill: "#808080" },
      }),
      _c("rect", {
        attrs: { y: "6", width: "2", height: "2", fill: "#808080" },
      }),
      _c("rect", {
        attrs: {
          x: "4.27979",
          y: "6",
          width: "15",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: { y: "12", width: "2", height: "2", fill: "#808080" },
      }),
      _c("rect", {
        attrs: {
          x: "4.27979",
          y: "12",
          width: "15",
          height: "2",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }