var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "25",
        height: "24",
        viewBox: "0 0 25 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "g",
        {
          attrs: { id: "_&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185;_1" },
        },
        [
          _c("g", { attrs: { id: "Group 7004" } }, [
            _c("path", {
              attrs: {
                id: "Vector",
                d: "M13.4925 23.3887C11.8853 23.3887 10.2782 23.43 8.67103 23.3805C4.98964 23.2567 1.75159 20.1857 1.23698 16.3883C0.856969 13.5733 1.617 11.1462 3.54083 9.14022C3.88917 8.77699 4.31669 8.49632 4.74421 8.23215C5.03714 8.04228 5.10839 7.82764 5.11631 7.48918C5.14006 6.51506 5.10047 5.52444 5.29048 4.58334C5.74175 2.29664 7.67349 0.678621 9.914 0.620834C11.2836 0.579558 12.6612 0.604324 14.0308 0.612579C14.7434 0.612579 15.1075 1.01708 15.1075 1.77657C15.1075 3.45238 15.1075 5.12819 15.1075 6.804C15.1075 7.53046 15.3767 8.1496 15.9705 8.54585C16.2159 8.71095 16.5405 8.82653 16.8334 8.82653C18.5356 8.85129 20.2378 8.83478 21.9399 8.84304C22.6762 8.84304 22.9929 9.12371 22.9929 9.88319C23.0008 12.8303 23.0087 15.7692 22.977 18.7163C22.9374 21.7047 20.2773 23.397 18.4089 23.4052C16.7701 23.4052 15.1313 23.4052 13.4925 23.4052V23.3887ZM15.1075 15.0922C15.1075 11.6416 12.4158 8.83478 9.09855 8.83478C5.813 8.83478 3.11331 11.6498 3.11331 15.0675C3.11331 18.5264 5.80509 21.3332 9.1223 21.3249C12.4079 21.3249 15.0996 18.5182 15.1075 15.0922Z",
                fill: "#13BF77",
              },
            }),
            _c("path", {
              attrs: {
                id: "Vector_2",
                d: "M22.3356 6.82896H17.1262V1.40527C18.86 3.20491 20.6097 5.02932 22.3356 6.83721V6.82896Z",
                fill: "#13BF77",
              },
            }),
            _c("path", {
              attrs: {
                id: "Vector_3",
                d: "M8.58386 15.3234C9.54973 14.2668 10.4523 13.2844 11.3548 12.2938C11.6477 11.9718 12.0119 11.8645 12.4236 11.9966C12.7957 12.1204 13.0728 12.4011 13.0728 12.7973C13.0728 13.111 12.9936 13.5073 12.7957 13.7137C11.6319 14.952 10.4285 16.1572 9.22514 17.3625C8.79762 17.7918 8.23551 17.7835 7.80008 17.3625C7.22214 16.7929 6.66003 16.215 6.10584 15.6124C5.71791 15.1914 5.73374 14.564 6.10584 14.1677C6.47794 13.7715 7.07963 13.7384 7.49923 14.1347C7.83966 14.4649 8.14843 14.8364 8.59178 15.3234H8.58386Z",
                fill: "#13BF77",
              },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }