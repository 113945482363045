var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Component 10" } }, [
        _c("path", {
          attrs: {
            id: "Union",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M9 17.5C8.44771 17.5 8 17.0523 8 16.5V10.5H2C1.44772 10.5 1 10.0523 1 9.5C1 8.94772 1.44771 8.5 2 8.5H8V2.5C8 1.94772 8.44771 1.5 9 1.5C9.55228 1.5 10 1.94772 10 2.5L10 8.5H16C16.5523 8.5 17 8.94771 17 9.5C17 10.0523 16.5523 10.5 16 10.5H10L10 16.5C10 17.0523 9.55228 17.5 9 17.5Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }