var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "pencil 1" } }, [
        _c("g", { attrs: { id: "Layer 2" } }, [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M10.2801 4.91064L3.10272 12.0881C2.83824 12.353 2.67109 12.6996 2.62841 13.0716L2.34243 15.5826C2.32378 15.7592 2.3426 15.9377 2.39765 16.1065C2.45269 16.2754 2.54273 16.4307 2.66187 16.5623C2.78102 16.694 2.9266 16.799 3.0891 16.8706C3.2516 16.9421 3.42737 16.9786 3.60493 16.9776H3.75141L6.26246 16.6917C6.63291 16.6488 6.97874 16.4845 7.24595 16.2243L14.4234 9.03993L10.2801 4.91064Z",
              fill: "#20A5F5",
            },
          }),
          _c("path", {
            attrs: {
              id: "Vector_2",
              d: "M16.8505 4.3034L15.03 2.48289C14.8777 2.32999 14.6967 2.20867 14.4974 2.12589C14.2981 2.0431 14.0844 2.00049 13.8686 2.00049C13.6528 2.00049 13.4391 2.0431 13.2398 2.12589C13.0406 2.20867 12.8596 2.32999 12.7073 2.48289L11.0193 4.17088L15.1625 8.31411L16.8505 6.62613C17.0034 6.47383 17.1247 6.29285 17.2075 6.09355C17.2903 5.89425 17.3329 5.68057 17.3329 5.46477C17.3329 5.24896 17.2903 5.03528 17.2075 4.83598C17.1247 4.63669 17.0034 4.4557 16.8505 4.3034Z",
              fill: "#20A5F5",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }