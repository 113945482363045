var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M21.3894 5.6786V16.9786C21.3894 18.9586 19.7794 20.5686 17.7994 20.5686C15.8294 20.5686 14.2094 18.9586 14.2094 16.9786C14.2094 15.0086 15.8294 13.3986 17.7994 13.3986C18.6394 13.3986 19.3894 13.6886 19.9994 14.1686V8.21861L10.7894 10.8386V18.9086C10.7894 20.8886 9.16936 22.4986 7.19936 22.4986C5.21936 22.4986 3.60938 20.8886 3.60938 18.9086C3.60938 16.9386 5.21936 15.3286 7.19936 15.3286C8.02938 15.3286 8.77938 15.6186 9.38938 16.0886V7.24861C9.38938 5.77861 10.2794 4.6386 11.6894 4.2586L17.4694 2.6786C18.6394 2.3586 19.6294 2.4686 20.3294 3.0086C21.0394 3.5386 21.3894 4.4386 21.3894 5.6786Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }