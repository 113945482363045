var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "story-city-input-label d-flex align-center" },
    [
      _vm.imageLocation
        ? _c("img", {
            staticClass: "story-city-label-icon",
            attrs: { src: _vm.imageLocation, alt: "" },
          })
        : _vm.iconName
        ? _c("v-icon", { staticClass: "mr-2", attrs: { color: "primary" } }, [
            _vm._v(_vm._s(_vm.iconName)),
          ])
        : _vm._e(),
      _c("div", [_vm._t("default")], 2),
      _vm.showWarning
        ? _c("v-icon", { staticClass: "ml-4", attrs: { size: "20" } }, [
            _vm._v("$warning"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }