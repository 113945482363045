var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "19",
        height: "18",
        viewBox: "0 0 19 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic", "clip-path": "url(#clip0_331_20673)" } }, [
        _c("path", {
          attrs: {
            id: "Color",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M8.41708 2.88987C5.54813 2.88987 3.22239 5.21561 3.22239 8.08456C3.22239 10.9535 5.54813 13.2792 8.41708 13.2792C11.286 13.2792 13.6118 10.9535 13.6118 8.08456C13.6118 5.21561 11.286 2.88987 8.41708 2.88987ZM0.777832 8.08456C0.777832 3.86552 4.19804 0.445312 8.41708 0.445312C12.6361 0.445312 16.0563 3.86552 16.0563 8.08456C16.0563 9.76101 15.5163 11.3113 14.6007 12.5711C14.6645 12.6142 14.7252 12.664 14.7816 12.7205L17.5311 15.4699C18.0084 15.9473 18.0084 16.7212 17.5311 17.1985C17.0538 17.6758 16.2799 17.6758 15.8025 17.1985L13.0531 14.449C12.9966 14.3926 12.9468 14.3319 12.9037 14.2681C11.6439 15.1837 10.0936 15.7238 8.41708 15.7238C4.19804 15.7238 0.777832 12.3036 0.777832 8.08456ZM6.89693 4.41447C7.37888 4.21484 7.89544 4.11209 8.4171 4.11209C9.09215 4.11209 9.63938 4.65933 9.63938 5.33437C9.63938 6.00942 9.09215 6.55665 8.4171 6.55665C8.21646 6.55665 8.01779 6.59617 7.83242 6.67295C7.64705 6.74973 7.47862 6.86227 7.33675 7.00415C7.19488 7.14602 7.08234 7.31445 7.00555 7.49982C6.92877 7.68518 6.88925 7.88386 6.88925 8.0845C6.88925 8.75955 6.34202 9.30678 5.66698 9.30678C4.99193 9.30678 4.4447 8.75955 4.4447 8.0845C4.4447 7.56284 4.54745 7.04628 4.74708 6.56433C4.94671 6.08237 5.23932 5.64446 5.60819 5.27558C5.97706 4.90671 6.41497 4.61411 6.89693 4.41447Z",
            fill: "#62BFF7",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_331_20673" } }, [
          _c("rect", {
            attrs: {
              width: "18",
              height: "18",
              fill: "white",
              transform: "translate(0.333496)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }