var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M7.22874 18.4894C5.76122 18.4894 4.45584 17.5498 3.98183 16.1509L3.94977 16.0455C3.83799 15.6751 3.79116 15.3635 3.79116 15.0518V8.80176L1.5673 16.2251C1.28128 17.317 1.93305 18.449 3.0266 18.7506L17.2014 22.5467C17.3783 22.5925 17.5553 22.6145 17.7295 22.6145C18.6424 22.6145 19.4766 22.0086 19.7105 21.1156L20.5363 18.4894H7.22874Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_2",
            d: "M9.74969 8.86365C10.7608 8.86365 11.583 8.04134 11.583 7.03021C11.583 6.01908 10.7608 5.19678 9.74969 5.19678C8.73856 5.19678 7.91626 6.01908 7.91626 7.03021C7.91626 8.04134 8.73856 8.86365 9.74969 8.86365Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_3",
            d: "M21.2083 2.44678H7.45793C6.19485 2.44678 5.16626 3.47537 5.16626 4.73861V14.8221C5.16626 16.0853 6.19485 17.1139 7.45793 17.1139H21.2083C22.4715 17.1139 23.5001 16.0853 23.5001 14.8221V4.73861C23.5001 3.47537 22.4715 2.44678 21.2083 2.44678ZM7.45793 4.28021H21.2083C21.4614 4.28021 21.6667 4.48549 21.6667 4.73861V11.2462L18.7709 7.86718C18.4637 7.50698 18.0191 7.31445 17.5416 7.30354C17.0667 7.30623 16.6212 7.51705 16.3169 7.88195L12.9122 11.9684L11.8031 10.862C11.1762 10.2351 10.1558 10.2351 9.52971 10.862L6.99969 13.3912V4.73861C6.99969 4.48549 7.20498 4.28021 7.45793 4.28021Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }