var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "g",
        { attrs: { id: "resize", "clip-path": "url(#clip0_260_6828)" } },
        [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M17.4727 0H15.3633C15.0719 0 14.8359 0.235931 14.8359 0.527344V1.05469H3.16406V0.527344C3.16406 0.235931 2.92813 0 2.63672 0H0.527344C0.235931 0 0 0.235931 0 0.527344V2.63672C0 2.92813 0.235931 3.16406 0.527344 3.16406H1.05469V14.8359H0.527344C0.235931 14.8359 0 15.0719 0 15.3633V17.4727C0 17.7641 0.235931 18 0.527344 18H2.63672C2.92813 18 3.16406 17.7641 3.16406 17.4727V16.9453H14.8359V17.4727C14.8359 17.7641 15.0719 18 15.3633 18H17.4727C17.7641 18 18 17.7641 18 17.4727V15.3633C18 15.0719 17.7641 14.8359 17.4727 14.8359H16.9453V3.16406H17.4727C17.7641 3.16406 18 2.92813 18 2.63672V0.527344C18 0.235931 17.7641 0 17.4727 0ZM15.8906 14.8359H15.3633C15.0719 14.8359 14.8359 15.0719 14.8359 15.3633V15.8906H3.16406V15.3633C3.16406 15.0719 2.92813 14.8359 2.63672 14.8359H2.10938V3.16406H2.63672C2.92813 3.16406 3.16406 2.92813 3.16406 2.63672V2.10938H14.8359V2.63672C14.8359 2.92813 15.0719 3.16406 15.3633 3.16406H15.8906V14.8359Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              id: "Vector_2",
              d: "M14.3086 4.25391H3.69141C3.39999 4.25391 3.16406 4.48984 3.16406 4.78125V10.891L6.51778 7.57246C6.72377 7.36647 7.05748 7.36647 7.26347 7.57246L10.0547 10.3637L11.7912 8.62715C11.9972 8.42116 12.3309 8.42116 12.5369 8.62715L14.8359 10.891V4.78125C14.8359 4.48984 14.6 4.25391 14.3086 4.25391ZM11.1094 7.41797C10.8181 7.41797 10.582 7.1819 10.582 6.89062C10.582 6.59935 10.8181 6.36328 11.1094 6.36328C11.4007 6.36328 11.6367 6.59935 11.6367 6.89062C11.6367 7.1819 11.4007 7.41797 11.1094 7.41797Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              id: "Vector_3",
              d: "M10.4275 11.4826C10.2215 11.6886 9.88783 11.6886 9.68184 11.4826L6.89062 8.69141L3.16406 12.3828V13.2191C3.16406 13.5106 3.39999 13.7465 3.69141 13.7465H14.3086C14.6 13.7465 14.8359 13.5106 14.8359 13.2191V12.3828L12.1641 9.74609L10.4275 11.4826Z",
              fill: "white",
            },
          }),
        ]
      ),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_260_6828" } }, [
          _c("rect", { attrs: { width: "18", height: "18", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }