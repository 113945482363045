var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "text-format 2" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M14.075 1H3.925C2.8631 1 2 1.87337 2 2.94792V16.0521C2 17.1266 2.8631 18 3.925 18H14.075C15.1369 18 16 17.1266 16 16.0521V2.94792C16 1.87337 15.1369 1 14.075 1ZM4.1 7.19792C4.1 6.90467 4.3352 6.66667 4.625 6.66667H8.475C8.7648 6.66667 9 6.90467 9 7.19792V7.90625C9 8.1995 8.7648 8.4375 8.475 8.4375C8.1852 8.4375 7.95 8.1995 7.95 7.90625V7.72917H7.075V10.5625C7.3648 10.5625 7.6 10.8005 7.6 11.0938C7.6 11.387 7.3648 11.625 7.075 11.625H6.025C5.7352 11.625 5.5 11.387 5.5 11.0938C5.5 10.8005 5.7352 10.5625 6.025 10.5625V7.72917H5.15V7.90625C5.15 8.1995 4.9148 8.4375 4.625 8.4375C4.3352 8.4375 4.1 8.1995 4.1 7.90625V7.19792ZM13.375 15.875H4.625C4.3352 15.875 4.1 15.637 4.1 15.3438C4.1 15.0505 4.3352 14.8125 4.625 14.8125H13.375C13.6648 14.8125 13.9 15.0505 13.9 15.3438C13.9 15.637 13.6648 15.875 13.375 15.875ZM13.375 13.75H4.625C4.3352 13.75 4.1 13.512 4.1 13.2188C4.1 12.9255 4.3352 12.6875 4.625 12.6875H13.375C13.6648 12.6875 13.9 12.9255 13.9 13.2188C13.9 13.512 13.6648 13.75 13.375 13.75ZM13.375 11.625H10.925C10.6352 11.625 10.4 11.387 10.4 11.0938C10.4 10.8005 10.6352 10.5625 10.925 10.5625H13.375C13.6648 10.5625 13.9 10.8005 13.9 11.0938C13.9 11.387 13.6648 11.625 13.375 11.625ZM13.375 9.67708H10.925C10.6352 9.67708 10.4 9.43908 10.4 9.14583C10.4 8.85258 10.6352 8.61458 10.925 8.61458H13.375C13.6648 8.61458 13.9 8.85258 13.9 9.14583C13.9 9.43908 13.6648 9.67708 13.375 9.67708ZM13.375 7.72917H10.925C10.6352 7.72917 10.4 7.49117 10.4 7.19792C10.4 6.90467 10.6352 6.66667 10.925 6.66667H13.375C13.6648 6.66667 13.9 6.90467 13.9 7.19792C13.9 7.49117 13.6648 7.72917 13.375 7.72917Z",
            fill: "#1FA4F4",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }