var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "17",
        height: "16",
        viewBox: "0 0 17 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Frame 22" } }, [
        _c("path", {
          attrs: {
            id: "Chevron",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M12.9163 7.51725C13.176 7.78408 13.176 8.21517 12.9163 8.4827L6.75299 14.8216C6.37811 15.2076 5.76817 15.2076 5.39261 14.8216C5.01773 14.4355 5.01773 13.809 5.39261 13.4229L10.665 7.99963L5.39261 2.57771C5.01773 2.19097 5.01773 1.56444 5.39261 1.17839C5.76817 0.79235 6.37811 0.79235 6.75299 1.17839L12.9163 7.51725Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }