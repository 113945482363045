var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "25",
        viewBox: "0 0 24 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "12",
          cy: "12.6777",
          r: "12",
          fill: "#D9D9D9",
          "fill-opacity": "0.1",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "12",
          cy: "12.6777",
          r: "11.3333",
          stroke: "#D9D9D9",
          "stroke-opacity": "0.8",
          "stroke-width": "1.33333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.6712 15.2573V15.1096C10.675 14.4239 10.7393 13.8785 10.8643 13.4732C10.9931 13.0679 11.175 12.7421 11.4098 12.4959C11.6446 12.2459 11.9306 12.0167 12.2678 11.8084C12.4988 11.6645 12.7053 11.5073 12.8871 11.3368C13.0727 11.1626 13.2185 10.9694 13.3246 10.7573C13.4306 10.5414 13.4837 10.3008 13.4837 10.0357C13.4837 9.73645 13.4136 9.47698 13.2734 9.25728C13.1333 9.03758 12.9439 8.86713 12.7053 8.74592C12.4704 8.6247 12.2071 8.5641 11.9155 8.5641C11.6465 8.5641 11.3909 8.62281 11.1484 8.74023C10.9098 8.85387 10.7109 9.02811 10.5518 9.26296C10.3965 9.49402 10.3094 9.78758 10.2905 10.1436H8.26776C8.2867 9.42395 8.46094 8.82167 8.79048 8.33683C9.12382 7.85198 9.56321 7.48834 10.1087 7.24592C10.6579 7.00349 11.264 6.88228 11.9268 6.88228C12.6503 6.88228 13.2867 7.00917 13.8359 7.26296C14.389 7.51675 14.8189 7.87849 15.1257 8.34819C15.4363 8.8141 15.5916 9.36713 15.5916 10.0073C15.5916 10.4391 15.5215 10.8255 15.3814 11.1664C15.245 11.5073 15.05 11.8103 14.7962 12.0755C14.5424 12.3406 14.2412 12.5774 13.8928 12.7857C13.5859 12.9751 13.334 13.1721 13.1371 13.3766C12.9439 13.5811 12.8 13.8217 12.7053 14.0982C12.6143 14.3709 12.567 14.708 12.5632 15.1096V15.2573H10.6712ZM11.6598 18.8027C11.3189 18.8027 11.0253 18.6815 10.7791 18.4391C10.5329 18.1967 10.4098 17.9012 10.4098 17.5527C10.4098 17.2118 10.5329 16.9202 10.7791 16.6777C11.0253 16.4353 11.3189 16.3141 11.6598 16.3141C11.9969 16.3141 12.2886 16.4353 12.5348 16.6777C12.7848 16.9202 12.9098 17.2118 12.9098 17.5527C12.9098 17.7838 12.8511 17.994 12.7337 18.1834C12.62 18.3728 12.4685 18.5243 12.2791 18.638C12.0935 18.7478 11.8871 18.8027 11.6598 18.8027Z",
          fill: "#4CB6F6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }