var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "8",
        height: "10",
        viewBox: "0 0 8 10",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M7.7899 3.70075C7.7899 4.50296 7.66095 5.06668 7.42455 5.60871C6.93025 6.73615 6.22104 7.71181 5.38288 8.57907C4.99604 8.96933 4.5877 9.33791 4.20086 9.7065C3.98595 9.90163 3.83551 9.87995 3.6206 9.7065C2.82542 9.03438 2.09472 8.27553 1.47148 7.42995C0.955686 6.73614 0.525861 6.02066 0.246475 5.21845C-0.226332 3.89588 -0.0114194 2.63836 0.826739 1.53261C1.38551 0.795437 2.11621 0.318446 3.01884 0.101632C3.98595 -0.115183 4.88858 0.014906 5.76972 0.491897C6.54341 0.925525 7.10218 1.55429 7.46753 2.3565C7.68244 2.83349 7.7899 3.35384 7.7899 3.70075ZM3.89998 6.36756C5.21095 6.36756 6.26402 5.28349 6.26402 3.96092C6.26402 2.63836 5.18946 1.57597 3.89998 1.57597C2.58902 1.57597 1.53595 2.66004 1.53595 3.9826C1.53595 5.30517 2.58902 6.36756 3.89998 6.36756Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }