var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between mb-3" },
        [
          _c("h2", { staticClass: "dialog__subtitle" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("story.create.selectStoryTemplate")) + " "
            ),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { "content-class": "story-city-tooltip", bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "img",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                src: require("@/assets/icons/question_mark.svg"),
                              },
                            },
                            "img",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("story.create.selectTemplateTooltip"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "templates-container pr-1", attrs: { elevation: "0" } },
        [
          _c(
            "div",
            { staticClass: "scroll-container pa-2" },
            [
              _c(
                "v-list",
                {
                  staticClass: "template-list d-flex flex-column",
                  attrs: { subheader: "", "two-line": "" },
                },
                _vm._l(_vm.templates, function (template) {
                  return _c(
                    "v-list-item",
                    {
                      key: template.id,
                      staticClass: "template-list-item",
                      class: {
                        "selected-list-item":
                          _vm.selectedTemplate &&
                          template.id === _vm.selectedTemplate.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectTemplate(template)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "template-icon-container" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "template-icon",
                              attrs: { size: "24", color: "#636262" },
                            },
                            [_vm._v(" " + _vm._s(template.templateIcon) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "template-list-item-title pa-0" },
                            [_vm._v(" " + _vm._s(template.name) + " ")]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "template-list-item-subtitle" },
                            [
                              _vm._v(
                                " " + _vm._s(template.templateDescription) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-list-item-action", { staticClass: "ma-0 mr-5" }, [
                        template.templatePreviewLink
                          ? _c(
                              "a",
                              {
                                staticClass: "preview-link",
                                attrs: {
                                  href: template.templatePreviewLink,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("common.preview")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }