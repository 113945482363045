var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: { viewBox: "1 1 18 18", xmlns: "http://www.w3.org/2000/svg" },
    },
    [
      _c("path", {
        staticStyle: { "stroke-width": "0px" },
        attrs: {
          d: "M 4.5 1 L 13.5 1 C 15.433 1 17 2.567 17 4.5 L 17 13.5 C 17 15.433 15.433 17 13.5 17 L 4.5 17 C 2.567 17 1 15.433 1 13.5 L 1 4.5 C 1 2.567 2.567 1 4.5 1 Z M 3.714 5.69 L 2.251 6.617 L 2.251 7.724 L 3.605 6.876 L 3.639 6.876 L 3.639 11.601 L 4.889 11.601 L 4.889 5.69 Z M 10.36 11.601 L 10.36 10.579 L 7.877 10.579 L 7.877 10.539 L 8.74 9.693 C 9.147 9.323 9.461 9.007 9.684 8.744 C 9.907 8.478 10.063 8.236 10.149 8.019 C 10.238 7.802 10.282 7.581 10.282 7.358 C 10.282 7.018 10.194 6.715 10.019 6.451 C 9.844 6.188 9.599 5.982 9.283 5.834 C 8.969 5.683 8.601 5.609 8.177 5.609 C 7.766 5.609 7.402 5.687 7.087 5.843 C 6.77 5.998 6.526 6.219 6.351 6.504 C 6.175 6.788 6.088 7.123 6.088 7.511 L 7.274 7.511 C 7.274 7.323 7.311 7.16 7.384 7.023 C 7.456 6.887 7.561 6.781 7.695 6.708 C 7.83 6.635 7.988 6.599 8.169 6.599 C 8.342 6.599 8.496 6.634 8.634 6.703 C 8.77 6.77 8.878 6.867 8.957 6.994 C 9.036 7.119 9.075 7.268 9.075 7.442 C 9.075 7.597 9.043 7.743 8.98 7.877 C 8.916 8.011 8.823 8.146 8.7 8.284 C 8.578 8.424 8.429 8.579 8.25 8.752 L 6.145 10.7 L 6.145 11.601 Z M 13.917 10.7 L 13.917 9.173 L 15.443 9.173 L 15.443 8.14 L 13.917 8.14 L 13.917 6.613 L 12.883 6.613 L 12.883 8.14 L 11.356 8.14 L 11.356 9.173 L 12.883 9.173 L 12.883 10.7 Z",
          fill: "#20A5F5",
          stroke: "#20A5F5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }