var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "arrow" } }, [
        _c("path", {
          attrs: {
            id: "Polygon 1",
            d: "M7.86603 12.5C7.48113 13.1667 6.51887 13.1667 6.13397 12.5L1.80385 5C1.41895 4.33333 1.90007 3.5 2.66987 3.5L11.3301 3.5C12.0999 3.5 12.5811 4.33333 12.1962 5L7.86603 12.5Z",
            fill: "#2C2C2C",
            "fill-opacity": "0.6",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }