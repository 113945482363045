<template>
  <div class="home text-center">
    <v-img
      :src="require('@/assets/logo.svg')"
      class="my-3"
      contain
      height="120"
    />
    <v-container>
      <v-row>
        <v-col>
          <h1>{{ $t("becomeACreator.welcome") }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>{{ $t("becomeACreator.startWalkthrough") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <iframe
            width="860"
            height="485"
            src="https://www.youtube.com/embed/dN94GZpoIic"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="isCreator" style="position: relative">
            <v-row>
              <v-col>
                <h3>{{ $t("becomeACreator.startCreating") }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CreateStoryButton />
              </v-col>
            </v-row>
          </div>
          <div v-else-if="user">
            <v-row>
              <v-col>
                <h3>{{ $t("becomeACreator.startMakingAdventure") }}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :to="{ name: 'become-creator' }"
                  class="mx-5"
                  color="primary"
                  >{{ $t("becomeACreator.becomeCreator") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" width="500">
      <v-card color="secondaryBackground">
        <v-card-title class="text-center">
          <h3 style="width: 100%">{{ $t("register.accountVerification") }}</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="text-center">
            <v-row align="center" class="pa-15">
              <v-col>
                <v-row>
                  <v-col>
                    <v-avatar size="65" color="green">
                      <v-icon color="white" size="80">mdi-check-circle</v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    ><h2>
                      {{ $t("register.verification.accountIsVerified") }}
                    </h2></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  class="px-15"
                  elevation="0"
                  @click="dialog = false"
                  >{{ $t("forms.ok") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import hasLoggedInUser from "@/mixins/hasLoggedInUser";
import CreateStoryButton from "@/components/CreatorTools/CreateStoryButton.vue";
export default {
  mixins: [hasLoggedInUser],
  name: "Home",
  components: {
    CreateStoryButton,
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    if (this.$route.query.ver == 1) {
      this.dialog = true;
    }
  },
};
</script>
