// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/share_story_bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-sidebar[data-v-7ba5bd90] {\n  border-radius: 0px 30px 30px 0px;\n  box-shadow: 0px 5px 14.66667px 0px rgba(0, 0, 0, 0.06), 0px 0.66667px 6.66667px 0px rgba(0, 0, 0, 0.04);\n}\n.app-sidebar .v-toolbar__content[data-v-7ba5bd90] {\n  padding: 0px;\n}\n.app-navbar[data-v-7ba5bd90] {\n  height: 88px;\n  overflow: hidden;\n}\n.main-container[data-v-7ba5bd90] {\n  margin-top: 88px;\n  padding: 0px 60px;\n  transition: padding 0.3s ease-in-out;\n  flex: 1;\n}\n.background-container[data-v-7ba5bd90] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  padding-left: 0;\n  padding-right: 0;\n}\n@media (min-width: 1600px) {\n.sidebar[data-v-7ba5bd90] {\n    padding-left: 366px;\n}\n.background-container.sidebar[data-v-7ba5bd90] {\n    padding-left: 306px;\n}\n}\n.v-list .v-list-item[data-v-7ba5bd90] {\n  border-left: 5px solid transparent;\n}\n.menu-icon[data-v-7ba5bd90] {\n  width: 32px;\n  height: 32px;\n}\n.creator-menu-text[data-v-7ba5bd90] {\n  color: var(--v-secondaryText-base);\n  font-size: 18px;\n  font-weight: 700;\n}\n@media only screen and (max-width: 959.98px) {\n.main-container[data-v-7ba5bd90] {\n    padding: 0px 20px;\n}\n.background-container[data-v-7ba5bd90] {\n    padding: 0;\n}\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
