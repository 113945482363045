var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "10",
        height: "12",
        viewBox: "0 0 10 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.722301 12V0.363636H5.38139C6.23745 0.363636 6.95147 0.49053 7.52344 0.744318C8.09541 0.998106 8.52533 1.35038 8.81321 1.80114C9.10109 2.24811 9.24503 2.76326 9.24503 3.34659C9.24503 3.80114 9.15412 4.20076 8.9723 4.54545C8.79048 4.88636 8.54048 5.16667 8.2223 5.38636C7.90791 5.60227 7.54806 5.75568 7.14276 5.84659V5.96023C7.58594 5.97917 8.00071 6.10417 8.38707 6.33523C8.77723 6.56629 9.09351 6.89015 9.33594 7.30682C9.57836 7.7197 9.69957 8.21212 9.69957 8.78409C9.69957 9.40152 9.54617 9.95265 9.23935 10.4375C8.93632 10.9186 8.48745 11.2992 7.89276 11.5795C7.29806 11.8598 6.5651 12 5.69389 12H0.722301ZM3.18253 9.98864H5.18821C5.87382 9.98864 6.37382 9.85795 6.68821 9.59659C7.0026 9.33144 7.1598 8.97917 7.1598 8.53977C7.1598 8.2178 7.08215 7.93371 6.92685 7.6875C6.77154 7.44129 6.54995 7.24811 6.26207 7.10795C5.97798 6.9678 5.63897 6.89773 5.24503 6.89773H3.18253V9.98864ZM3.18253 5.23295H5.00639C5.34351 5.23295 5.64276 5.17424 5.90412 5.05682C6.16927 4.93561 6.3776 4.76515 6.52912 4.54545C6.68442 4.32576 6.76207 4.0625 6.76207 3.75568C6.76207 3.33523 6.61245 2.99621 6.31321 2.73864C6.01776 2.48106 5.5973 2.35227 5.05185 2.35227H3.18253V5.23295Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }