var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic/delete" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M2.979 3.125H6.1665V2.77083C6.1665 1.7944 6.9609 1 7.93734 1H10.0623C11.0388 1 11.8332 1.7944 11.8332 2.77083V3.125H15.0207C15.4117 3.125 15.729 3.44233 15.729 3.83333V4.89583C15.729 5.28683 15.4117 5.60417 15.0207 5.60417H2.979C2.588 5.60417 2.27067 5.28683 2.27067 4.89583V3.83333C2.27067 3.44233 2.588 3.125 2.979 3.125ZM10.4165 2.77083C10.4165 2.57569 10.2575 2.41667 10.0623 2.41667H7.93734C7.74219 2.41667 7.58317 2.57569 7.58317 2.77083V3.125H10.4165V2.77083Z",
            fill: "white",
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_2",
            d: "M3.23439 6.54448C3.36862 6.39679 3.55916 6.3125 3.75856 6.3125H14.2419C14.4413 6.3125 14.6318 6.39679 14.7661 6.54448C14.9003 6.69217 14.9658 6.88979 14.947 7.08848L14.0765 16.1863C13.9777 17.2205 13.0678 18.0004 11.96 18.0004H6.04046C4.93298 18.0004 4.02277 17.2205 3.92395 16.1863L3.05341 7.08848C3.03429 6.88979 3.10016 6.69217 3.23439 6.54448ZM10.6556 16.2277C10.6708 16.2285 10.6857 16.2292 10.7009 16.2292C11.0717 16.2292 11.3834 15.9409 11.4071 15.5655L11.8321 8.83629C11.8566 8.446 11.5601 8.10954 11.1699 8.08475C10.7785 8.0635 10.4427 8.35675 10.4183 8.74704L9.99331 15.4762C9.96887 15.8665 10.2653 16.203 10.6556 16.2277ZM6.59331 15.5655C6.61704 15.9409 6.92871 16.2292 7.29952 16.2292C7.31439 16.2292 7.32962 16.2288 7.34485 16.2277C7.7355 16.203 8.03158 15.8665 8.00714 15.4762L7.58214 8.74704C7.55735 8.3564 7.22266 8.06279 6.8306 8.08475C6.43996 8.10954 6.14387 8.446 6.16831 8.83629L6.59331 15.5655Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }