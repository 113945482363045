var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "21",
        height: "13",
        viewBox: "0 0 21 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.0936644 12V0.363636H2.20162V5.28977H7.59367V0.363636H9.7073V12H7.59367V7.05682H2.20162V12H0.0936644ZM15.8424 12.1591C15.0242 12.1591 14.297 12.0189 13.6606 11.7386C13.028 11.4583 12.528 11.0682 12.1606 10.5682C11.7932 10.0682 11.5981 9.49053 11.5754 8.83523H13.7117C13.7307 9.14962 13.8348 9.42424 14.0242 9.65909C14.2136 9.89015 14.4655 10.0701 14.7799 10.1989C15.0943 10.3277 15.4466 10.392 15.8367 10.392C16.2534 10.392 16.6227 10.3201 16.9447 10.1761C17.2667 10.0284 17.5186 9.82386 17.7004 9.5625C17.8822 9.30114 17.9712 9 17.9674 8.65909C17.9712 8.30682 17.8803 7.99621 17.6947 7.72727C17.5091 7.45833 17.2401 7.24811 16.8879 7.09659C16.5394 6.94508 16.1189 6.86932 15.6265 6.86932H14.5981V5.24432H15.6265C16.0318 5.24432 16.386 5.17424 16.689 5.03409C16.9958 4.89394 17.2364 4.69697 17.4106 4.44318C17.5848 4.18561 17.6701 3.88826 17.6663 3.55114C17.6701 3.22159 17.5962 2.93561 17.4447 2.69318C17.297 2.44697 17.0867 2.25568 16.814 2.11932C16.5451 1.98295 16.2288 1.91477 15.8651 1.91477C15.5091 1.91477 15.1795 1.97917 14.8765 2.10795C14.5735 2.23674 14.3292 2.42045 14.1436 2.65909C13.9579 2.89394 13.8595 3.17424 13.8481 3.5H11.8197C11.8348 2.84848 12.0223 2.27652 12.3822 1.78409C12.7458 1.28788 13.2307 0.901515 13.8367 0.625C14.4428 0.344697 15.1227 0.204545 15.8765 0.204545C16.653 0.204545 17.3273 0.350378 17.8992 0.642045C18.475 0.929924 18.9201 1.31818 19.2345 1.80682C19.5489 2.29545 19.7061 2.83523 19.7061 3.42614C19.7098 4.08144 19.5167 4.63068 19.1265 5.07386C18.7401 5.51705 18.2326 5.80682 17.6038 5.94318V6.03409C18.422 6.14773 19.0489 6.45076 19.4845 6.94318C19.9239 7.43182 20.1417 8.03977 20.1379 8.76705C20.1379 9.41856 19.9523 10.0019 19.5811 10.517C19.2136 11.0284 18.7061 11.4299 18.0583 11.7216C17.4144 12.0133 16.6757 12.1591 15.8424 12.1591Z",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }