var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: "--zoom: " + _vm.zoom },
    [
      _c(
        "v-badge",
        {
          key: _vm.user ? _vm.user.id : 0,
          staticClass: "avatar-badge",
          attrs: {
            color: "green",
            avatar: "",
            bottom: "",
            icon: "mdi-feather",
            overlap: "",
            value: _vm.isCreator,
            "offset-x": "20",
            "offset-y": "20",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }