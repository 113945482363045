var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "46",
        height: "46",
        viewBox: "0 0 46 46",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Frame 15" } }, [
        _c("rect", {
          attrs: {
            id: "Rectangle 3.4",
            x: "0.333496",
            y: "0.333252",
            width: "45.3333",
            height: "45.3333",
            rx: "12",
            fill: "white",
            "fill-opacity": "0.2",
          },
        }),
        _c("path", {
          attrs: {
            id: "Union",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M23 15C22.0182 15 21.2222 15.7959 21.2222 16.7778V21.2222H16.7778C15.7959 21.2222 15 22.0182 15 23C15 23.9818 15.7959 24.7778 16.7778 24.7778H21.2222V29.2222C21.2222 30.2041 22.0182 31 23 31C23.9818 31 24.7778 30.2041 24.7778 29.2222V24.7778H29.2222C30.2041 24.7778 31 23.9818 31 23C31 22.0182 30.2041 21.2222 29.2222 21.2222H24.7778V16.7778C24.7778 15.7959 23.9818 15 23 15Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }