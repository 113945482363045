var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "story-city-button",
          style: _vm.disabled ? "" : `background-image: ${_vm.gradientStyle};`,
          attrs: { disabled: _vm.disabled },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.on
    ),
    [
      _c(
        "div",
        { class: !_vm.disabled ? "button-text " : "" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }