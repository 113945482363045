var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "21",
        height: "15",
        viewBox: "0 0 21 15",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.52002",
          y: "0.800781",
          width: "4",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.7998",
          y: "0.800781",
          width: "14",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.52002",
          y: "6.80078",
          width: "4",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.7998",
          y: "6.80078",
          width: "14",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.52002",
          y: "12.8008",
          width: "4",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.7998",
          y: "12.8008",
          width: "14",
          height: "2",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }