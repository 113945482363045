var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$apollo.queries.user.loading
        ? _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "" },
          })
        : !_vm.user
        ? _c(
            "v-btn",
            {
              staticClass: "mx-10",
              attrs: { color: "primary", to: "/auth/sign-in" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("login.loginNoun")) + " ")]
          )
        : _vm.user
        ? _c(
            "v-menu",
            {
              attrs: {
                "content-class": "story-city-tooltip rounded-lg",
                bottom: "",
                center: "",
                "offset-y": "",
                elevation: "0",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "RoleBadge",
                        { attrs: { user: _vm.user } },
                        [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "" } }, on),
                            [
                              _c("Avatar", {
                                attrs: { userProfile: _vm.user.userProfile },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.user
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-3",
                      attrs: { color: "secondaryBackground", width: "250px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "align-center justify-center text-center",
                        },
                        [
                          _c("AvatarWithBadge", { attrs: { user: _vm.user } }),
                          _vm.user.userProfile
                            ? _c("h3", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("fullName", {
                                        firstName:
                                          _vm.user.userProfile.firstName,
                                        lastName: _vm.user.userProfile.lastName,
                                      })
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _c("p", { staticClass: "text-caption mt-1" }, [
                            _vm._v(" " + _vm._s(_vm.user.email) + " "),
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "rounded-lg textOnPrimary--text",
                              attrs: {
                                color: "primaryLight",
                                depressed: "",
                                block: "",
                                to: { name: "user-settings" },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-cog"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("userSettings.userSettings")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _vm.user.userProfile && !_vm.user.isVerified
                            ? _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "rounded-lg textOnPrimary--text mt-3",
                                  attrs: {
                                    color: "secondaryLight",
                                    depressed: "",
                                    block: "",
                                    to: { name: "verify-email" },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "register.verification.verifyAccount"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("v-divider", { staticClass: "my-3" }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "rounded-lg",
                              attrs: {
                                block: "",
                                depressed: "",
                                rounded: "",
                                text: "",
                              },
                              on: { click: _vm.logout },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("login.logOutVerb")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }