var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Component 10" } }, [
        _c("path", {
          attrs: {
            id: "Chevron",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M9.48275 13.9273C9.21592 14.187 8.78483 14.187 8.5173 13.9273L2.17845 7.76398C1.79241 7.38909 1.79241 6.77915 2.17845 6.4036C2.56449 6.02871 3.19103 6.02871 3.57707 6.4036L9.00037 11.676L14.4223 6.4036C14.809 6.02871 15.4356 6.02871 15.8216 6.4036C16.2076 6.77915 16.2076 7.38909 15.8216 7.76398L9.48275 13.9273Z",
            fill: "#565656",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }