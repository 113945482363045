var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "23",
          height: "23",
          rx: "3.5",
          fill: "#1FA4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.2934 7.29341L10.0004 13.5864L7.70741 11.2934C7.5188 11.1112 7.2662 11.0105 7.00401 11.0127C6.74181 11.015 6.491 11.1202 6.30559 11.3056C6.12018 11.491 6.01501 11.7418 6.01273 12.004C6.01045 12.2662 6.11125 12.5188 6.29341 12.7074L9.29341 15.7074C9.48093 15.8949 9.73524 16.0002 10.0004 16.0002C10.2656 16.0002 10.5199 15.8949 10.7074 15.7074L17.7074 8.70741C17.8896 8.5188 17.9904 8.2662 17.9881 8.00401C17.9858 7.74181 17.8806 7.491 17.6952 7.30559C17.5098 7.12018 17.259 7.01501 16.9968 7.01273C16.7346 7.01045 16.482 7.11125 16.2934 7.29341Z",
          fill: "white",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "23",
          height: "23",
          rx: "3.5",
          stroke: "#1FA4F4",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }