var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "21",
        height: "18",
        viewBox: "0 0 21 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: { x: "0.640137", width: "20", height: "2", fill: "#808080" },
      }),
      _c("rect", {
        attrs: {
          x: "6.64014",
          y: "4",
          width: "14",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.640137",
          y: "8",
          width: "20",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "6.64014",
          y: "12",
          width: "14",
          height: "2",
          fill: "#808080",
        },
      }),
      _c("rect", {
        attrs: {
          x: "0.640137",
          y: "16",
          width: "20",
          height: "2",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }