var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_121_3126)" } }, [
        _c("path", {
          attrs: {
            d: "M9 1.74414C7.36831 1.74414 5.77325 2.22799 4.41655 3.13452C3.05984 4.04104 2.00242 5.32951 1.378 6.837C0.753575 8.34449 0.590197 10.0033 0.908525 11.6036C1.22685 13.204 2.01259 14.674 3.16637 15.8278C4.32016 16.9816 5.79017 17.7673 7.39051 18.0856C8.99085 18.4039 10.6497 18.2406 12.1571 17.6161C13.6646 16.9917 14.9531 15.9343 15.8596 14.5776C16.7661 13.2209 17.25 11.6258 17.25 9.99414C17.2474 7.8069 16.3774 5.70998 14.8308 4.16336C13.2842 2.61674 11.1872 1.74672 9 1.74414ZM11.7803 12.7744C11.6396 12.915 11.4489 12.994 11.25 12.994C11.0511 12.994 10.8604 12.915 10.7198 12.7744L8.46975 10.5244C8.32909 10.3838 8.25005 10.193 8.25 9.99414V5.49414C8.25 5.29523 8.32902 5.10446 8.46967 4.96381C8.61033 4.82316 8.80109 4.74414 9 4.74414C9.19892 4.74414 9.38968 4.82316 9.53033 4.96381C9.67099 5.10446 9.75 5.29523 9.75 5.49414V9.68364L11.7803 11.7139C11.9209 11.8545 11.9998 12.0453 11.9998 12.2441C11.9998 12.443 11.9209 12.6337 11.7803 12.7744Z",
            fill: "#20A5F5",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_121_3126" } }, [
          _c("rect", {
            attrs: {
              width: "18",
              height: "18",
              fill: "white",
              transform: "translate(0 0.994141)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }