var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "17",
        viewBox: "0 0 15 17",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.29982 9.66615C8.06741 9.13048 7.87635 8.5045 7.73818 7.81401C7.04775 7.67585 6.42183 7.48481 5.8862 7.25243C6.377 8.31627 7.23601 9.17531 8.29982 9.66615Z",
          fill: "#1FA4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.69944 9.12275C9.55839 8.80721 9.43118 8.43864 9.32467 8.02625C9.66209 8.05071 10.0083 8.06342 10.3612 8.06342C10.7138 8.06342 11.0599 8.05072 11.3971 8.02629C11.2906 8.43866 11.1634 8.80721 11.0224 9.12275C10.8139 9.58905 10.6046 9.87403 10.4479 10.0224C10.4117 10.0566 10.3827 10.0798 10.3609 10.0952C10.3391 10.0798 10.3101 10.0566 10.2739 10.0224C10.1172 9.87403 9.90787 9.58905 9.69944 9.12275Z",
          fill: "#1FA4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.3612 6.56342C10.8159 6.56342 11.2528 6.53996 11.6665 6.49698C11.7095 6.08315 11.733 5.64618 11.733 5.19134C11.733 4.73648 11.7095 4.2995 11.6665 3.88566C11.2528 3.84267 10.8159 3.81922 10.3612 3.81922C9.90624 3.81922 9.46918 3.84269 9.05526 3.88572C9.01226 4.29955 8.9888 4.73651 8.9888 5.19134C8.9888 5.64616 9.01226 6.08311 9.05526 6.49692C9.46918 6.53994 9.90624 6.56342 10.3612 6.56342Z",
          fill: "#1FA4F4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.42978 4.52986C6.74524 4.38885 7.11369 4.26167 7.52595 4.15518C7.5015 4.49249 7.4888 4.8386 7.4888 5.19134C7.4888 5.54407 7.5015 5.89016 7.52594 6.22745C7.11369 6.12096 6.74524 5.99378 6.42978 5.85278C5.96348 5.64435 5.6785 5.43505 5.53016 5.27829C5.49593 5.24212 5.47276 5.21316 5.45729 5.19132C5.47276 5.16948 5.49593 5.14052 5.53016 5.10435C5.6785 4.94759 5.96348 4.73829 6.42978 4.52986Z",
          fill: "#1FA4F4",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2.5 0.5H13.3333C13.7754 0.5 14.1993 0.668571 14.5118 0.968629C14.8244 1.26869 15 1.67565 15 2.1V13.3H2.51C2.17333 13.308 1.78 13.4208 1.68667 13.8816C1.67417 13.9472 1.66667 14.0192 1.66667 14.1C1.66667 14.7448 2.125 14.8904 2.51 14.9H15V16.5H2.5C1.495 16.5 0 15.8608 0 14.1V2.9C0 1.1392 1.495 0.5 2.5 0.5ZM12.4221 9.66624C12.6545 9.13057 12.8455 8.5046 12.9837 7.81411C13.348 7.74121 13.6944 7.65358 14.019 7.55293V5.96639C13.7699 6.06257 13.4944 6.15051 13.1958 6.2276C13.2203 5.89027 13.233 5.54413 13.233 5.19134C13.233 4.83854 13.2203 4.49239 13.1958 4.15503C13.4944 4.23212 13.7699 4.32006 14.019 4.41625V2.82974C13.6944 2.72908 13.348 2.64145 12.9836 2.56855C12.907 2.1856 12.8141 1.8225 12.7069 1.48364H11.1165C11.2203 1.74559 11.3148 2.0379 11.3971 2.35635C11.0599 2.33192 10.7138 2.31922 10.3612 2.31922C10.0083 2.31922 9.6621 2.33193 9.32469 2.35639C9.40694 2.03793 9.50154 1.7456 9.60532 1.48364H8.01495C7.90774 1.82253 7.81483 2.18566 7.73818 2.56864C7.04777 2.70681 6.42186 2.89785 5.88623 3.13024C6.17818 2.49745 6.60041 1.93712 7.11852 1.48364H5.11257C4.3712 2.53118 3.93555 3.8104 3.93555 5.19137C3.93555 8.74008 6.81234 11.6169 10.361 11.6169C11.7202 11.6169 12.9809 11.1949 14.019 10.4747V8.48986C13.5746 8.98248 13.032 9.38487 12.4221 9.66624Z",
          fill: "#1FA4F4",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }