var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "arrow (1) 1" } }, [
        _c("g", { attrs: { id: "Layer 34" } }, [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M10.9685 4.40979H5.20291L6.15916 3.45354C6.36234 3.21218 6.46734 2.90325 6.45333 2.58807C6.43932 2.27289 6.30732 1.9745 6.08352 1.75212C5.85973 1.52975 5.5605 1.39964 5.24524 1.38763C4.92998 1.37562 4.62172 1.48258 4.38166 1.68729L1.28791 4.78104C1.16904 4.90026 1.07522 5.04204 1.01194 5.19805C0.948671 5.35406 0.917231 5.52114 0.919473 5.68948C0.922379 6.01817 1.05476 6.33246 1.28791 6.56416L4.38166 9.66916C4.61774 9.90488 4.93779 10.0371 5.2714 10.0369C5.60501 10.0366 5.92486 9.90384 6.16057 9.66776C6.39628 9.43167 6.52855 9.11162 6.52829 8.77801C6.52802 8.4444 6.39524 8.12456 6.15916 7.88885L5.19728 6.92698H10.9685C11.93 6.92698 12.8521 7.30893 13.532 7.98881C14.2119 8.66868 14.5938 9.5908 14.5938 10.5523C14.5938 11.5138 14.2119 12.4359 13.532 13.1158C12.8521 13.7956 11.93 14.1776 10.9685 14.1776H2.1626C1.82693 14.1776 1.50502 14.3109 1.26767 14.5483C1.03031 14.7856 0.896973 15.1076 0.896973 15.4432C0.896973 15.7789 1.03031 16.1008 1.26767 16.3382C1.50502 16.5755 1.82693 16.7088 2.1626 16.7088H10.9685C12.5976 16.7088 14.16 16.0617 15.3119 14.9098C16.4639 13.7578 17.111 12.1954 17.111 10.5664C17.111 8.93726 16.4639 7.37489 15.3119 6.22295C14.16 5.07101 12.5976 4.42385 10.9685 4.42385V4.40979Z",
              fill: "#565656",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }