var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "18",
        height: "16",
        viewBox: "0 0 18 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic/save" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M2.91574 2.44087L4.00071 1.3559C4.44154 0.914423 5.03929 0.666992 5.66298 0.666992H14.7171C15.6099 0.666992 16.3335 1.39056 16.3335 2.28342V13.1572C16.3335 14.0501 15.6099 14.7737 14.7171 14.7737H3.84325C2.95039 14.7737 2.22683 14.0501 2.22683 13.1572V4.10315C2.22683 3.47946 2.47426 2.88171 2.91574 2.44087ZM13.9824 4.34057V2.87116C13.9824 2.62782 13.7849 2.43033 13.5416 2.43033H7.36992C7.12658 2.43033 6.92909 2.62782 6.92909 2.87116V4.34057C6.92909 4.58391 7.12658 4.7814 7.36992 4.7814H13.5416C13.7849 4.7814 13.9824 4.58391 13.9824 4.34057ZM9.28016 12.4226C10.7407 12.4226 11.9252 11.2382 11.9252 9.77758C11.9252 8.31689 10.7407 7.13258 9.28016 7.13258C7.81958 7.13258 6.63516 8.31689 6.63516 9.77758C6.63516 11.2382 7.81958 12.4226 9.28016 12.4226Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }