var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog story-city-dialog-card",
      attrs: {
        "content-class": "story-city-dialog",
        "max-width": _vm.defaultDialogWidth,
        elevation: "0",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "StoryCityGradientButton",
                _vm._b(
                  {
                    staticClass:
                      "story-city-button button-box-shadow pa-1 pr-8",
                    attrs: { "x-large": "", on: on },
                  },
                  "StoryCityGradientButton",
                  attrs,
                  false
                ),
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-3", attrs: { size: "45" } },
                        [_vm._v(" $add ")]
                      ),
                      _c("div", { staticClass: "create-button__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("story.create.createAStory")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "dialog-card px-10 py-12",
          attrs: { color: "secondaryBackground" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog-button",
              attrs: { absolute: "", icon: "", color: "tertiaryText" },
              on: {
                click: function ($event) {
                  _vm.dialog = false
                },
              },
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "v-container",
            { staticClass: "pa-0" },
            [
              _c("h2", { staticClass: "dialog__title mb-6" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("story.create.createANewStory")) + " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between mb-3" },
                [
                  _c("h4", { staticClass: "dialog__subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "story.create.keepingItBriefWhatsYourStoryAbout?"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        "content-class": "story-city-tooltip",
                        bottom: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "img",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        src: require("@/assets/icons/question_mark.svg"),
                                      },
                                    },
                                    "img",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("story.create.aShortDescriptionYouCanEdit")
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("Tagline", {
                staticClass: "mb-5",
                attrs: { "hide-label": "" },
                model: {
                  value: _vm.summary,
                  callback: function ($$v) {
                    _vm.summary = $$v
                  },
                  expression: "summary",
                },
              }),
              _c("SelectTemplate", {
                staticClass: "mb-4",
                on: {
                  "template-selected": function ($event) {
                    _vm.selectedTemplate = $event
                  },
                },
              }),
              _c("ApolloMutation", {
                attrs: {
                  mutation: (gql) => _vm.createStoryQuery,
                  variables: {
                    summary: _vm.summary,
                    templateId: _vm.selectedTemplate,
                  },
                  refetchQueries: _vm.refetchQueries,
                },
                on: { done: _vm.onDone },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ mutate, loading, error }) {
                      return [
                        _c(
                          "StoryCityGradientButton",
                          {
                            attrs: {
                              block: "",
                              "x-large": "",
                              loading: loading,
                              disabled: !_vm.buttonActive || loading,
                            },
                            on: {
                              click: function ($event) {
                                return mutate({
                                  refetchQueries: ["GetMyStories"],
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("story.create.createYourStory")) +
                                " "
                            ),
                          ]
                        ),
                        error
                          ? _c("div", [_vm._v(" " + _vm._s(error) + " ")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }