var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "paper 1" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M14.9174 5.88097L11.6467 2.53147C11.2478 2.13273 10.6894 1.89348 10.0513 1.89348H5.34471C4.14812 1.81372 3.11108 2.85048 3.11108 4.04672V15.2117C3.11108 16.4079 4.06835 17.4447 5.34471 17.4447H13.3219C14.5185 17.4447 15.5555 16.4877 15.5555 15.2117V7.39622C15.5555 6.83797 15.3162 6.27972 14.9174 5.88097ZM6.94015 8.19375H9.33331C9.65243 8.19375 9.97147 8.43299 9.97147 8.83176C9.97147 9.23045 9.73215 9.46969 9.33331 9.46969H6.94015C6.62106 9.46969 6.30196 9.23045 6.30196 8.83176C6.30196 8.43299 6.62106 8.19375 6.94015 8.19375ZM11.7264 12.6597H6.94015C6.62106 12.6597 6.30196 12.4205 6.30196 12.0217C6.30196 11.623 6.54129 11.3837 6.94015 11.3837H11.7264C12.0456 11.3837 12.3646 11.623 12.3646 12.0217C12.3646 12.4205 12.0456 12.6597 11.7264 12.6597Z",
            fill: "#20A5F5",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }