var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "24",
        height: "20",
        viewBox: "0 0 24 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Group" } }, [
        _c("g", { attrs: { id: "Group_2" } }, [
          _c("path", {
            attrs: {
              id: "Vector",
              d: "M6.83698 7.00955H9.0937V13.7041C9.0937 14.3833 9.64441 14.9333 10.3229 14.9333H13.6782C14.3567 14.9333 14.9074 14.3834 14.9074 13.7041V7.00955H17.1634C17.5371 7.00955 17.8731 6.78452 18.0168 6.43915C18.159 6.09519 18.0799 5.69755 17.8158 5.43349L12.6529 0.269901C12.2923 -0.089967 11.7081 -0.089967 11.3467 0.269901L6.18464 5.43349C5.92053 5.69755 5.8414 6.09451 5.98439 6.43915C6.12801 6.78384 6.46324 7.00955 6.83698 7.00955Z",
              fill: "#1FA4F4",
            },
          }),
        ]),
        _c("g", { attrs: { id: "Group_3" } }, [
          _c("path", {
            attrs: {
              id: "Vector_2",
              d: "M22.041 9.51953C21.4293 9.51953 20.933 10.0159 20.933 10.6283V15.2643C20.933 16.6531 19.8024 17.7829 18.4136 17.7829H5.58672C4.19786 17.7829 3.06808 16.6531 3.06808 15.2643V10.6283C3.06808 10.0159 2.57104 9.51953 1.95933 9.51953C1.3469 9.51953 0.850586 10.0159 0.850586 10.6283V15.2644C0.850586 17.8758 2.97518 20.0005 5.58672 20.0005H18.4136C21.0251 20.0005 23.1498 17.8759 23.1498 15.2644V10.6283C23.1498 10.0159 22.6535 9.51953 22.041 9.51953Z",
              fill: "#1FA4F4",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }