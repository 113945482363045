var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "19",
        height: "18",
        viewBox: "0 0 19 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Component 10" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M15.254 1.22752C16.4644 1.06921 17.4619 2.0667 17.3036 3.27711C17.0722 5.04636 16.4368 8.05195 14.6402 9.98913C14.0685 10.6055 13.3781 11.175 12.668 11.6809L12.8755 13.1333C12.9339 13.5423 12.7964 13.9548 12.5042 14.247L10.1177 16.6335C9.35295 17.3982 8.0433 16.9617 7.89035 15.891L7.57215 13.6636L7.5731 13.6635C7.36989 13.5651 7.18146 13.4321 7.0173 13.2679L5.26316 11.5137C5.09899 11.3496 4.96595 11.1612 4.86758 10.9579L4.86745 10.959L2.64006 10.6407C1.56937 10.4878 1.13282 9.17808 1.8976 8.4133L4.28409 6.02685C4.5762 5.73473 4.98881 5.5972 5.39778 5.65562L6.85013 5.8631C7.35611 5.15301 7.9256 4.46252 8.54198 3.89087C10.4791 2.09424 13.4847 1.45891 15.254 1.22752ZM3.75377 11.5953C3.97344 11.815 3.97344 12.1711 3.75377 12.3908L2.69311 13.4514C2.47344 13.6711 2.11728 13.6711 1.89762 13.4514C1.67795 13.2318 1.67795 12.8757 1.89762 12.656L2.95827 11.5953C3.17795 11.3757 3.5341 11.3757 3.75377 11.5953ZM5.34476 13.1863C5.56443 13.406 5.56443 13.7622 5.34476 13.9818L3.22344 16.1031C3.00377 16.3228 2.64762 16.3228 2.42795 16.1031C2.20827 15.8835 2.20827 15.5273 2.42795 15.3076L4.54926 13.1863C4.76894 12.9666 5.12509 12.9666 5.34476 13.1863ZM6.93575 14.7773C7.15541 14.997 7.15541 15.3531 6.93575 15.5728L5.87509 16.6335C5.65542 16.8531 5.29926 16.8531 5.0796 16.6335C4.85993 16.4138 4.85993 16.0576 5.0796 15.8379L6.14025 14.7773C6.35993 14.5576 6.71608 14.5576 6.93575 14.7773ZM10.7804 7.7503C10.1946 7.16455 10.1946 6.21481 10.7804 5.62902C11.3662 5.04323 12.3159 5.04323 12.9017 5.62902C13.4875 6.21481 13.4875 7.16455 12.9017 7.7503C12.3159 8.33613 11.3662 8.33613 10.7804 7.7503Z",
            fill: "white",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }