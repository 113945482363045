var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-tag",
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_121_3134)" } }, [
        _c("path", {
          attrs: {
            d: "M4.85501 16.6276C5.39466 17.1673 6.27066 17.1673 6.81031 16.6276L13.5042 9.93376C13.6964 9.74156 13.8442 9.51239 13.9404 9.26844C14.6131 9.45695 15.2673 9.55675 15.8439 9.55305C17.2707 9.54566 17.7438 8.92099 17.899 8.54767C18.0543 8.17805 18.1651 7.40184 17.1635 6.38168C17.1524 6.37059 17.1413 6.3595 17.1302 6.34841C16.5092 5.72745 15.6074 5.15453 14.5835 4.72946C14.395 4.65184 14.2065 4.58161 14.0217 4.51508V4.50399C14.0106 3.68343 13.349 3.0218 12.5284 3.01071L8.51797 2.95527C7.98572 2.94788 7.47564 3.15487 7.10232 3.53188L0.404738 10.2258C-0.134913 10.7654 -0.134913 11.6414 0.404738 12.1811L4.85501 16.6276ZM10.0149 5.35043C10.4622 4.90319 11.1867 4.90319 11.6339 5.35043C11.9 5.61656 12.0072 5.98249 11.9555 6.32624C12.3953 6.63672 12.9202 6.92503 13.482 7.15789C13.6816 7.23921 13.8738 7.31313 14.0586 7.37597L14.0365 5.76441C14.0697 5.77919 14.0993 5.79028 14.1326 5.80507C15.0086 6.17099 15.7959 6.66629 16.3022 7.17267C16.3096 7.18007 16.317 7.18746 16.3244 7.19485C16.8049 7.68645 16.8567 8.00063 16.816 8.09673C16.6904 8.39613 15.3412 8.66226 13.2935 7.80843C12.6947 7.55708 12.1329 7.2466 11.6783 6.91024C11.6635 6.92872 11.645 6.9472 11.6302 6.96568C11.183 7.41293 10.4585 7.41293 10.0113 6.96568C9.56771 6.52214 9.56771 5.79767 10.0149 5.35043Z",
            fill: "#20A5F5",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_121_3134" } }, [
          _c("rect", {
            attrs: {
              width: "18",
              height: "18",
              fill: "white",
              transform: "translate(0 0.994141)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }