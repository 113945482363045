var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "12",
        height: "13",
        viewBox: "0 0 12 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.27628 3.5625C8.22325 3.06629 7.99976 2.67992 7.60582 2.40341C7.21567 2.12689 6.7081 1.98864 6.0831 1.98864C5.6437 1.98864 5.26681 2.05492 4.95241 2.1875C4.63802 2.32008 4.39749 2.5 4.23082 2.72727C4.06416 2.95455 3.97893 3.21402 3.97514 3.50568C3.97514 3.74811 4.03007 3.95833 4.13991 4.13636C4.25355 4.31439 4.40696 4.46591 4.60014 4.59091C4.79332 4.71212 5.00734 4.81439 5.24219 4.89773C5.47704 4.98106 5.71378 5.05114 5.95241 5.10795L7.04332 5.38068C7.48272 5.48295 7.90507 5.62121 8.31037 5.79545C8.71946 5.9697 9.08499 6.18939 9.40696 6.45455C9.73272 6.7197 9.99029 7.03977 10.1797 7.41477C10.3691 7.78977 10.4638 8.22917 10.4638 8.73295C10.4638 9.41477 10.2895 10.0152 9.94105 10.5341C9.59257 11.0492 9.08878 11.4527 8.42969 11.7443C7.77438 12.0322 6.98082 12.1761 6.04901 12.1761C5.1437 12.1761 4.35772 12.036 3.69105 11.7557C3.02817 11.4754 2.50923 11.0663 2.13423 10.5284C1.76302 9.99053 1.56226 9.33523 1.53196 8.5625H3.60582C3.63613 8.9678 3.76113 9.30492 3.98082 9.57386C4.20052 9.8428 4.48651 10.0436 4.83878 10.1761C5.19484 10.3087 5.59257 10.375 6.03196 10.375C6.49029 10.375 6.89181 10.3068 7.23651 10.1705C7.58499 10.0303 7.85772 9.83712 8.05469 9.59091C8.25166 9.34091 8.35204 9.04924 8.35582 8.71591C8.35204 8.41288 8.26302 8.16288 8.08878 7.96591C7.91454 7.76515 7.67022 7.59848 7.35582 7.46591C7.04522 7.32955 6.68158 7.20833 6.26491 7.10227L4.94105 6.76136C3.98272 6.51515 3.22514 6.14205 2.66832 5.64205C2.11529 5.13826 1.83878 4.4697 1.83878 3.63636C1.83878 2.95076 2.02438 2.35038 2.3956 1.83523C2.7706 1.32008 3.28007 0.920454 3.92401 0.636363C4.56795 0.348484 5.29711 0.204545 6.11151 0.204545C6.93726 0.204545 7.66075 0.348484 8.28196 0.636363C8.90696 0.920454 9.39749 1.31629 9.75355 1.82386C10.1096 2.32765 10.2933 2.9072 10.3047 3.5625H8.27628Z",
          fill: "#808080",
        },
      }),
      _c("path", {
        attrs: {
          d: "M0.804688 6.76136H11.1911V7.85227H0.804688V6.76136Z",
          fill: "#808080",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }