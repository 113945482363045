var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M12 1C5.93464 1 1 5.9345 1 11.9999C1 18.0654 5.93464 23 12 23C18.0654 23 23 18.0654 23 11.9999C23 5.9345 18.0655 1 12 1ZM18.3164 10.142L11.4057 17.0527C11.1118 17.3465 10.7212 17.5082 10.3057 17.5082C9.89017 17.5082 9.49957 17.3465 9.20572 17.0527L5.68361 13.5306C5.38976 13.2367 5.22792 12.8461 5.22792 12.4306C5.22792 12.015 5.38976 11.6244 5.68361 11.3305C5.97734 11.0367 6.36794 10.8748 6.78357 10.8748C7.19909 10.8748 7.58981 11.0367 7.88354 11.3306L10.3056 13.7526L16.1162 7.94193C16.4101 7.64808 16.8007 7.48635 17.2162 7.48635C17.6317 7.48635 18.0223 7.64808 18.3162 7.94193C18.9229 8.54867 18.9229 9.53545 18.3164 10.142Z",
            fill: "#1FA4F4",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }