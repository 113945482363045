var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "c/cross" } }, [
        _c("path", {
          attrs: {
            id: "Union",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M10.0339 12.6664L1.76788 4.62931C1.18882 4.06698 1.18882 3.15207 1.76788 2.58874C2.34694 2.02641 3.28675 2.02641 3.86581 2.58874L12.0008 10.4973L20.1336 2.58874C20.7138 2.02641 21.6536 2.02641 22.2326 2.58874C22.8117 3.15207 22.8117 4.06698 22.2326 4.62931L13.9665 12.6665L22.2325 20.7036C22.8116 21.2659 22.8116 22.1808 22.2325 22.7441C21.6535 23.3065 20.7137 23.3065 20.1346 22.7441L11.9996 14.8356L3.86677 22.7441C3.28666 23.3065 2.34686 23.3065 1.76779 22.7441C1.18873 22.1808 1.18873 21.2659 1.76779 20.7036L10.0339 12.6664Z",
            fill: "#2C2C2C",
            "fill-opacity": "0.8",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }