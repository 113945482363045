var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      !_vm.hideSidebar
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "app-sidebar",
              attrs: {
                app: "",
                width: "19.125rem",
                "mobile-breakpoint": "1600",
                color: "secondaryBackground",
                permanent: !_vm.isFeatureEnabled("hiddenSidebar"),
              },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-start px-3",
                  style: `height: ${_vm.navBarHeight}px;`,
                },
                [
                  _vm.isFeatureEnabled("hiddenSidebar")
                    ? _c(
                        "StoryCityIconBtn",
                        {
                          staticClass: "mr-6",
                          attrs: { color: "background" },
                          on: { click: () => (_vm.drawer = !_vm.drawer) },
                        },
                        [
                          _c("img", {
                            staticClass: "menu-icon",
                            attrs: {
                              src: require("@/assets/icons/menu.svg"),
                              alt: _vm.$t("alts.menuIcon"),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticStyle: { height: "auto", "line-height": "1" },
                      attrs: { to: "/" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/storycity_logo_text.svg"),
                          alt: _vm.$t("alts.scLogo"),
                          contain: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("router-view", { attrs: { name: "sidebar" } }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-app-bar",
        {
          staticClass: "app-navbar",
          attrs: {
            app: "",
            permanent: "",
            "clipped-left": "",
            height: _vm.navBarHeight,
            flat: "",
            color: "secondaryBackground",
          },
        },
        [
          !_vm.hideSidebar && _vm.isFeatureEnabled("hiddenSidebar")
            ? _c(
                "StoryCityIconBtn",
                {
                  staticClass: "mr-6",
                  attrs: { color: "background" },
                  on: { click: () => (_vm.drawer = !_vm.drawer) },
                },
                [
                  _c("img", {
                    staticClass: "menu-icon",
                    attrs: {
                      src: require("@/assets/icons/menu.svg"),
                      alt: _vm.$t("alts.menuIcon"),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              staticStyle: { height: "auto", "line-height": "1" },
              attrs: { to: "/" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/storycity_logo_text.svg"),
                  alt: _vm.$t("alts.scLogo"),
                  contain: "",
                },
              }),
            ]
          ),
          _c("v-spacer"),
          _vm.isAdmin
            ? _c("v-btn", { staticClass: "mx-5", attrs: { to: "/admin" } }, [
                _vm._v(" " + _vm._s(_vm.$t("admin.admin")) + " "),
              ])
            : _vm._e(),
          _vm.isCreator
            ? _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c("CreateStoryButton"),
                  _c("div", {
                    staticStyle: {
                      height: "1px",
                      width: "1px",
                      position: "absolute",
                      top: "55px",
                      left: "50%",
                    },
                    attrs: { id: "create-story-button" },
                  }),
                ],
                1
              )
            : _vm.user
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-5",
                  attrs: { to: { name: "become-creator" }, color: "primary" },
                },
                [_vm._v(_vm._s(_vm.$t("becomeACreator.title")))]
              )
            : _vm._e(),
          _vm.isCreator
            ? _c(
                "router-link",
                {
                  staticClass: "creator-menu-text ml-7 mr-13",
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/creator-dashboard" },
                },
                [_vm._v(_vm._s(_vm.$t("creator.creatorMenu")))]
              )
            : _vm._e(),
          _c("AvatarMenu"),
        ],
        1
      ),
      _c(
        "v-container",
        {
          class:
            !_vm.hideSidebar && _vm.drawer
              ? "main-container sidebar"
              : "main-container",
          attrs: { fluid: "" },
        },
        [_c("router-view")],
        1
      ),
      _c("ErrorSnackbar", {
        attrs: {
          errorLabel: _vm.$t("serverError"),
          errorMessage: _vm.$te("errorCodes." + _vm.errorCode)
            ? _vm.$t("errorCodes." + _vm.errorCode)
            : _vm.errorMessage,
        },
        model: {
          value: _vm.snackbar,
          callback: function ($$v) {
            _vm.snackbar = $$v
          },
          expression: "snackbar",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }