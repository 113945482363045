var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticClass: "pa-15 font-weight-bold",
      attrs: { "multi-line": "", color: "error", timeout: 10000 },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { color: "red", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.snackbar = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-close-circle"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "align-content": "center", "align-items": "center" } },
            [
              _c(
                "v-col",
                { staticClass: "align-self-center", attrs: { cols: "auto" } },
                [
                  _c("v-img", {
                    staticClass: "pr-8",
                    attrs: {
                      src: require("@/assets/icons/server_warning_icon.svg"),
                      contain: "",
                      "max-width": "50",
                      height: "50",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "font-weight-black",
                  staticStyle: { "font-size": "1.1rem" },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pt-4" },
                    [
                      _vm.errorLabel != ""
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0 text-uppercase" },
                                [_vm._v(_vm._s(_vm.errorLabel) + ":")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "pa-0" }, [
                            _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }