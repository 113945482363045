var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "48",
        height: "45",
        viewBox: "0 0 48 45",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Layer 2" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M40.7802 0.425781H7.21993C5.31263 0.425781 3.48345 1.18345 2.13478 2.53212C0.786115 3.88079 0.0284424 5.70997 0.0284424 7.61727V29.1917C0.0284424 31.099 0.786115 32.9282 2.13478 34.2769C3.48345 35.6256 5.31263 36.3832 7.21993 36.3832H14.0279C14.3879 36.3849 14.743 36.4676 15.0667 36.6253C15.3904 36.783 15.6744 37.0116 15.8977 37.2941L20.2605 42.7357C20.7097 43.2957 21.279 43.7476 21.9262 44.0581C22.5735 44.3686 23.2822 44.5298 24.0001 44.5298C24.718 44.5298 25.4267 44.3686 26.0739 44.0581C26.7212 43.7476 27.2904 43.2957 27.7396 42.7357L32.1025 37.2941C32.3257 37.0116 32.6097 36.783 32.9334 36.6253C33.2571 36.4676 33.6122 36.3849 33.9723 36.3832H40.7802C42.6875 36.3832 44.5167 35.6256 45.8654 34.2769C47.214 32.9282 47.9717 31.099 47.9717 29.1917V7.61727C47.9717 5.70997 47.214 3.88079 45.8654 2.53212C44.5167 1.18345 42.6875 0.425781 40.7802 0.425781Z",
            fill: "#FF5C5C",
          },
        }),
        _c("g", { attrs: { id: "Group" } }, [
          _c("path", {
            attrs: {
              id: "Vector_2",
              d: "M24.0001 21.9995C23.3643 21.9995 22.7546 21.7469 22.305 21.2974C21.8555 20.8478 21.6029 20.2381 21.6029 19.6023V12.4108C21.6029 11.7751 21.8555 11.1653 22.305 10.7158C22.7546 10.2662 23.3643 10.0137 24.0001 10.0137C24.6358 10.0137 25.2456 10.2662 25.6951 10.7158C26.1447 11.1653 26.3972 11.7751 26.3972 12.4108V19.6023C26.3972 20.2381 26.1447 20.8478 25.6951 21.2974C25.2456 21.7469 24.6358 21.9995 24.0001 21.9995Z",
              fill: "white",
            },
          }),
          _c("path", {
            attrs: {
              id: "Vector_3",
              d: "M24.0001 28.5912C25.324 28.5912 26.3972 27.518 26.3972 26.194C26.3972 24.8701 25.324 23.7969 24.0001 23.7969C22.6762 23.7969 21.6029 24.8701 21.6029 26.194C21.6029 27.518 22.6762 28.5912 24.0001 28.5912Z",
              fill: "white",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }