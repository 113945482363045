var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "21",
        height: "22",
        viewBox: "0 0 21 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Layer 2" } }, [
        _c("path", {
          attrs: {
            id: "Vector",
            d: "M12.0001 3.5C11.408 3.50076 10.8174 3.55979 10.2369 3.67625C10.5478 4.65838 10.5831 5.70704 10.3388 6.70784C10.0946 7.70864 9.58016 8.62314 8.85171 9.35159C8.12327 10.08 7.20876 10.5944 6.20796 10.8387C5.20716 11.083 4.1585 11.0477 3.17637 10.7368C2.8128 12.5565 3.02066 14.4442 3.77148 16.1413C4.5223 17.8384 5.77937 19.2619 7.37055 20.2169C8.96173 21.1718 10.8092 21.6116 12.66 21.4759C14.5108 21.3402 16.2744 20.6357 17.7093 19.4589C19.1442 18.2821 20.1802 16.6905 20.6755 14.9021C21.1708 13.1136 21.1011 11.2158 20.476 9.46848C19.8509 7.72117 18.701 6.20981 17.1837 5.14136C15.6664 4.07291 13.8559 3.49962 12.0001 3.5ZM16.5001 13.25H12.0001C11.8012 13.25 11.6104 13.171 11.4698 13.0303C11.3291 12.8897 11.2501 12.6989 11.2501 12.5V8C11.2501 7.80109 11.3291 7.61032 11.4698 7.46967C11.6104 7.32902 11.8012 7.25 12.0001 7.25C12.199 7.25 12.3898 7.32902 12.5305 7.46967C12.6711 7.61032 12.7501 7.80109 12.7501 8V11.75H16.5001C16.699 11.75 16.8898 11.829 17.0305 11.9697C17.1711 12.1103 17.2501 12.3011 17.2501 12.5C17.2501 12.6989 17.1711 12.8897 17.0305 13.0303C16.8898 13.171 16.699 13.25 16.5001 13.25Z",
            fill: "#F5792F",
          },
        }),
        _c("path", {
          attrs: {
            id: "Vector_2",
            d: "M9.75 5.375C9.75 4.41082 9.46409 3.46829 8.92842 2.6666C8.39275 1.86491 7.63137 1.24007 6.74058 0.871089C5.84979 0.502112 4.86959 0.405571 3.92394 0.593674C2.97828 0.781777 2.10964 1.24608 1.42786 1.92786C0.746076 2.60964 0.281777 3.47828 0.093674 4.42394C-0.094429 5.36959 0.00211226 6.34979 0.371089 7.24058C0.740067 8.13137 1.36491 8.89275 2.1666 9.42842C2.96829 9.96409 3.91082 10.25 4.875 10.25C6.16745 10.2484 7.4065 9.73429 8.32039 8.82039C9.23429 7.9065 9.74841 6.66745 9.75 5.375ZM4.125 3.5C4.125 3.30109 4.20402 3.11032 4.34467 2.96967C4.48532 2.82902 4.67609 2.75 4.875 2.75C5.07391 2.75 5.26468 2.82902 5.40533 2.96967C5.54598 3.11032 5.625 3.30109 5.625 3.5V5C5.625 5.19891 5.54598 5.38968 5.40533 5.53033C5.26468 5.67098 5.07391 5.75 4.875 5.75C4.67609 5.75 4.48532 5.67098 4.34467 5.53033C4.20402 5.38968 4.125 5.19891 4.125 5V3.5ZM4.125 7.25C4.125 7.10167 4.16899 6.95666 4.2514 6.83332C4.33381 6.70999 4.45094 6.61386 4.58799 6.55709C4.72503 6.50033 4.87583 6.48547 5.02132 6.51441C5.16681 6.54335 5.30044 6.61478 5.40533 6.71967C5.51022 6.82456 5.58165 6.9582 5.61059 7.10368C5.63953 7.24917 5.62468 7.39997 5.56791 7.53701C5.51115 7.67406 5.41502 7.79119 5.29168 7.8736C5.16834 7.95602 5.02334 8 4.875 8C4.67609 8 4.48532 7.92098 4.34467 7.78033C4.20402 7.63968 4.125 7.44891 4.125 7.25Z",
            fill: "#F5792F",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }