import { render, staticRenderFns } from "./AgreementCheckboxBlank.vue?vue&type=template&id=3d6afa4c"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/web-client/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d6afa4c')) {
      api.createRecord('3d6afa4c', component.options)
    } else {
      api.reload('3d6afa4c', component.options)
    }
    module.hot.accept("./AgreementCheckboxBlank.vue?vue&type=template&id=3d6afa4c", function () {
      api.rerender('3d6afa4c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Icons/AgreementCheckboxBlank.vue"
export default component.exports