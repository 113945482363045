var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "ic/arrow" } }, [
        _c("path", {
          attrs: {
            id: "Chevron",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M3.74994 8.48251C3.49025 8.21568 3.49025 7.78458 3.74994 7.51706L9.91327 1.1782C10.2882 0.792161 10.8981 0.792161 11.2736 1.1782C11.6485 1.56425 11.6485 2.19078 11.2736 2.57683L6.00126 8.00013L11.2736 13.422C11.6485 13.8088 11.6485 14.4353 11.2736 14.8214C10.8981 15.2074 10.2882 15.2074 9.91327 14.8214L3.74994 8.48251Z",
            fill: "#7C7E80",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }